export default {
  // Endpoints
  loginEndpoint(prefix) {
    return `/api/${prefix}/login`;
  },
  registerEndpoint(prefix) {
    return `/api/${prefix}/register`;
  },
  refreshEndpoint(prefix) {
    return `/api/${prefix}/refresh-token`;
  },
  logoutEndpoint(prefix) {
    return `/api/${prefix}/logout`;
  },

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
};

import Vue from 'vue';

// axios
import axios from 'axios';

let baseURL;

const prodURL =
  window.location.origin.includes('indsiders.com') &&
  !window.location.origin.includes('dev') &&
  !window.location.origin.includes('homolog');
const homologURL = window.location.origin.includes('homolog');

if (prodURL) {
  baseURL = 'https://api.indsiders.com';
} else if (homologURL) {
  baseURL = 'https://app-troika-api-homolog.azurewebsites.net';
} else {
  baseURL = 'https://app-troika-api-dev.azurewebsites.net';
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
